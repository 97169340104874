// Color and font overrides for react-datepicker
// TODO: Remove this component and node-sass from dependencies
// once a separate datepicker is available.

// theme.indigo3
$datepicker__selected-color: #5246F7;

// theme.grey1
$datepicker__text-color: #34323A;
$datepicker__header-color: #34323A;

$datepicker__font-family: "EuclidCircularB";

@import 'react-datepicker/src/stylesheets/datepicker.scss';

.react-datepicker-wrapper input {
  width: 120px;
}
